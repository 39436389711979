const prepareHeader = () => {
    let header = { 'Content-Type': 'application/json' }
    let token = localStorage.getItem("token")
    if(token !== null){
        header.Authorization = `Bearer ${token}`
    }
    header.Accept = `${window.settings.apiToken}`
    return header
}

const prepareHeaderUpload = () => {
    let header = {  }
    let token = localStorage.getItem("token")
    if(token !== null){
        header.Authorization = `Bearer ${token}`
    }
    header.Accept = `${window.settings.apiToken}`
    return header
}

const filterUserData = (response) =>{
    const dataNew = {
        seller_info: response.seller_info,
        isAdmin: response.isAdmin,
        reseller: response.reseller
    }
    localStorage.setItem("data", Buffer.from(JSON.stringify(dataNew)).toString("base64"))              
}

export class api {
    static get = (path) => {
        return new Promise(
            (resolve, reject) => {
                fetch(window.settings.apiUrl + path, {
                    headers: prepareHeader()
                })
                .then(response => {
                    if(response.ok){
                        return response.json() 
                    }else{
                        reject(response.json()) 
                    }
                })
                .then(json => resolve(json))
                .catch(err =>{
                    reject(err)
                })
            }
        )
    }
    static post = (path, data) => {
        return new Promise(
            (resolve, reject) => {
                fetch(window.settings.apiUrl + path, {
                    method: 'POST',
                    headers: prepareHeader(),
                    body: JSON.stringify(data)
                })
                .then(response => { 
                    if(response.ok){
                        return response.json() 
                    }else{
                        reject(response.json()) 
                    }
                })
                .then(json => resolve(json))
                .catch(err => reject(err))
            }
        )
    }
    static put = (path, data) => {
        return new Promise(
            (resolve, reject) => {
                fetch(window.settings.apiUrl + path, {
                    method: 'PUT',
                    headers: prepareHeader(),
                    body: JSON.stringify(data)
                })
                .then(response => { 
                    if(response.ok){
                        return response.json() 
                    }else{
                        reject(response) 
                    }
                })
                .then(json => resolve(json))
                .catch(err => reject(err))
            }
        )
    }
    static delete = (path) => {
        return new Promise(
            (resolve, reject) => {
                fetch(window.settings.apiUrl + path, {
                    method: 'DELETE',
                    headers: prepareHeader()
                })
                .then(response => { 
                    if(response.ok){
                        return response.json() 
                    }else{
                        reject(response) 
                    }
                })
                .then(json => resolve(json))
                .catch(err => reject(err))
            }
        )
    }
    static url = (path) => {
        return window.settings.apiUrl + path
    }
    static upload = (data) => {
        return new Promise(
            (resolve, reject) => {
                fetch(window.settings.apiUrl + '/upload', {
                    headers: prepareHeaderUpload(),
                    method: 'POST',
                    body: data
                })
                .then(response => { 
                    if(response.ok){
                        return response.json() 
                    }else{
                        reject(response) 
                    }
                })
                .then(json => resolve(json))
                .catch(err => reject(err))
            }
        )
    }
}

// const checkAuthChange = (status, callback) => {
//     let token = localStorage.getItem("token")
//     setTimeout(() => { 
//         if(token !== null){
//             let tm = new Date(). getTime()
//             let jwt = token.split(".")[1];
//             if(jwt){
//                 jwt = JSON.parse(atob(jwt))
//                 if(tm > (jwt.exp*1000)){
//                     auth.logout();
//                     token = null
//                 }
//             }else{
//                 auth.logout();
//             }
//         }
        
//         if(status === 0 && token !== null){
//             status = 1
//             api.get("/users/me").then(res => {
//                 window.user = res
//                 callback({user: res})
//             });
//         }  
//         if(status === 1 && token === null){
//             status = 0
//             callback({user: null})
//         }        
//         checkAuthChange(status, callback)
//     }, 300);
// }

const checkAuthChange = (status, callback) => {
    let token = localStorage.getItem("token")
    setTimeout(() => {         
        if(status === 0 && token !== null){
            status = 1
            api.get("/users/me").then(res => {
                window.user = res
                callback({user: res})
            });
        }  
        if(status === 1 && token === null){
            status = 0
            callback({user: null})
        }        
        checkAuthChange(status, callback)
    }, 300);
}

export class auth {

    static onAuthStateChanged = callback => {
        let token = localStorage.getItem("token")
        if(token !== null){
            // api.get("/users/me").then(response => {    
            //     localStorage.setItem("data", Buffer.from(JSON.stringify(response)).toString("base64"))              
            //     window.user = response
            //     callback({ user: response })
            // }).catch(err => new Promise((resolve) => { resolve (err) }).then(res => { 
            //     this.logout();
            //     checkAuthChange(0, callback)
            //     callback({ user: null })
            //     window.user = null;
            // }))
            api.get("/users/me").then((response) => {  
                filterUserData(response)
                window.user = response
                callback({ user: response })
            }, (err) => {
                new Promise((resolve) => { resolve (err) }).then(res => { 
                    if(res.error === "Unauthorized"){
                        this.logout();
                    }
                    checkAuthChange(0, callback)
                    callback({ user: null })
                    window.user = null;
                    return Promise.reject(err)
                })
            })
        }else{
            checkAuthChange(0, callback)
            callback({ user: null })
            window.user = null;
        }   
    }

    static createUserWithEmailAndPassword = (credentials) => {
        return new Promise(
            (resolve, reject) => {
                api.post("/auth/local/register", {
                    name: credentials.name,
                    username: credentials.email,
                    email: credentials.email,
                    phone: credentials.phone,
                    password: credentials.password,
                    referral: credentials.referralcode,
                    promotion: credentials.promotion,
                }).then(response => {
                    // if(response.statusCode === 400){
                    //     reject(response.message[0].messages[0].message)
                    // }else{
                        // localStorage.setItem("token", response.jwt)
                        // api.put("/users/me", {
                        //     name: credentials.name
                        // }).then(res => {
                        //     resolve({user: response.user})
                        //     localStorage.setItem("data", Buffer.from(JSON.stringify(response.user)).toString("base64"))
                        // })
                    // }
                    if(response.user){
                        this.removeMiscToken();
                        resolve({ msg: "Berhasil register, Silahkan periksa email Anda untuk konfirmasi email!"})
                    }
                }).catch(err => new Promise((resolve) => { resolve (err) }).then(res => { 
                    reject(res.message[0].messages[0].message) 
                }));
            }
        )
    }

    static login = (credentials) => {
        localStorage.removeItem("token");
        localStorage.removeItem("data");
        return new Promise(
            (resolve, reject) => {
                api.post("/auth/local", {
                    identifier: credentials.username,
                    password: credentials.password
                }).then(response => {
                    // if(response.statusCode === 400 || response.statusCode === 401){
                    //     reject(response.message[0].messages[0].message)
                    //     localStorage.removeItem("token")
                    // }else{
                        this.removeMiscToken();
                        localStorage.setItem("token", response.jwt)
                        filterUserData(response.user)
                        resolve({user: response.user})
                    // }
                }).catch(err => new Promise((resolve) => { resolve (err) }).then(res => { 
                    reject(res)
                }));
            }
        )
    }

    static loginWithProvider = (provider, access_token) => {
        return new Promise(
            (resolve, reject) => {
                api.get("/auth/" + provider + "/callback?access_token=" + access_token)
                .then(response => {
                    // if(response.statusCode === 400 || response.statusCode === 401) {
                    //     reject(response.message[0].messages[0].id)
                    //     localStorage.removeItem("token")
                    // }else {
                        this.removeMiscToken();
                        localStorage.setItem("token", response.jwt)
                        filterUserData(response.user)
                        resolve({user: response.user})
                    // }
                }).catch(err => new Promise((resolve) => { resolve (err) }).then(res => { 
                    reject(res.message[0].messages[0].message) 
                }));
            }
        )
    }

    static currentUser = () => {
        return window.user
    }

    static removeMiscToken = () => {
        localStorage.removeItem("referralcode")
    }

    static logout = () => {
        this.removeMiscToken();
        let webview = localStorage.getItem("wv");
        if(webview !== null && webview === "dd83ac643f74fcace7712b7ea9759b2b"){
            window.location.assign("?tokenLogout="+localStorage.getItem("token"));
        }else{
            window.location.assign("/")
        }
        localStorage.removeItem("token")
        localStorage.removeItem("data")
        localStorage.removeItem("wv")
    }
}

export class metaTracking {
    static setTrack = (type, costumEvent, contentBody) => {
        if (window.fbq) {
            if(contentBody){
                window.fbq(type, costumEvent,contentBody);
            }else{
                window.fbq(type, costumEvent);
            }
        }
    }
}