import React, { Component, lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

// import Referral from './Account/Referral.js';
// import Deposit from './Vendor/Deposit'
// import Homev2 from './Home(v2)'
// import TermsVoucher from './TermsVoucher.js';
// import Giveaway from './Giveaway.js';
import ScrollToTop from './Components/ScrollToTop.js';
import LoadingApp from './Loadingpage/LoadingApp';
import { CampaignService } from "./Services/Campaign/CampaignService"

const Homev3 = lazy(() => import('./Home/Home(v3).js'));
const HomeReseller = lazy(() => import('./Home/Reseller/HomeReseller.js'));
const AccountMenu = lazy(() => import('./Account/AccountMenu.js'));
const Profile = lazy(() => import('./Account/Profile.js'));
const Address = lazy(() => import('./Account/Address.js'));
const Orders = lazy(() => import('./Account/Orders.js'));
const OrderDetail = lazy(() => import('./Account/OrderDetail.js'));
const DaftarVendor = lazy(() => import('./Account/DaftarVendor.js'));
const ListVoucher = lazy(() => import('./Account/ListVoucher.js'));
const InvoiceLabel = lazy(() => import('./Account/InvoiceLabel.js'));
const Notifikasi = lazy(() => import('./Account/Notif.js'));
const Products = lazy(() => import('./Products/Products.js'));
const Product = lazy(() => import('./Product/Product.js'));
const SellerPage = lazy(() => import('./SellerPage/SellerPage.js'));
const Cart = lazy(() => import('./Cart/Cart.js'));
const Checkout = lazy(() => import('./Checkout/Checkout.js'));
const AllChat = lazy(() => import('./Allchat/AllChat.js'));
const AllChatToko = lazy(() => import('./Allchat/AllChatToko.js'));
const TentangKami = lazy(() => import('./Tentangkami/TentangKami.js'));
const TermsCondition = lazy(() => import('./TermsCondition.js'));
const KebijakanPrivasi = lazy(() => import('./KebijakanPrivasi.js'));
const HubungiKami = lazy(() => import('./HubungiKami.js'));
const NotFoundPage = lazy(() => import('./NotFoundPage.js'));
const WishList = lazy(() => import('./Wishlist/WishList.js'));
const PointHistory = lazy(() => import('./Account/Point/PointHistory.js'));
const Referral = lazy(() => import('./Account/Referral.js'));
const RedirectPage = lazy(() => import('./Redirect.js'));
// const RedirectPayment = lazy(() => import('./RedirectPayment.js'));
const ConfirmPayment = lazy(() => import('./ConfirmPayment/ConfirmPaymentPage'));
const UnsubscribeLetter = lazy(() => import('./UnsubscribeLetter.js'));

const ResetPassword = lazy(() => import('./ResetPassword.js'));
const ThankYouPage = lazy(() => import('./Account/ThankYouPage.js'));
const Membership = lazy(() => import('./Account/Membership/Membership.js'));
const Statistic = lazy(() => import('./Account/Statistic/Statistic.js'));
const Reseller = lazy(() => import('./Account/Reseller/Reseller.js'));
const TermsEvent = lazy(() => import('./TermsEvent.js'));
const SellerMenu = lazy(() => import('./Vendor/SellerMenu.js'));
const ProductList = lazy(() => import('./Vendor/ProductList.js'));
const ProductImport = lazy(() => import('./Vendor/ProductImport.js'));
const ProductUpdateImport = lazy(() => import('./Vendor/ProductUpdateImport.js'));
const ProductDetail = lazy(() => import('./Vendor/ProductDetail.js'));
const VendorOrders = lazy(() => import('./Vendor/Orders.js'));
const VendorOrdersDetail = lazy(() => import('./Vendor/OrderDetail.js'));
const ShippingLabel = lazy(() => import('./Vendor/ShippingLabel.js'));
const Saldo = lazy(() => import('./Vendor/Saldo.js'));
const AmbilDitempat = lazy(() => import('./Vendor/AmbilDitempat.js'));
const AnalisisToko = lazy(() => import('./Vendor/AnalisisToko.js'));
const ProductListCampaign = lazy(() => import('./Vendor/Event/ProductListCampaign.js'));
const ProductListReseller = lazy(() => import('./Vendor/ProdukReseller/ProductListReseller.js'));
const ProductImportReseller = lazy(() => import('./Vendor/ProdukReseller/ProductImportReseller.js'));
const ProductUpdateImportReseller = lazy(() => import('./Vendor/ProdukReseller/ProductUpdateImportReseller.js'));
const ProductDetailReseller = lazy(() => import('./Vendor/ProdukReseller/ProductDetailReseller.js'));

const LoginAdmin = lazy(() => import('./AdminPanel/LoginAdmin.js'));
const Dashboard = lazy(() => import('./AdminPanel/Dashboard.js'));
const MasterVoucher = lazy(() => import('./AdminPanel/MasterVoucher.js'));
const MasterDepositSeller = lazy(() => import('./AdminPanel/MasterDepositSeller.js'));
const MasterSaldoSeller = lazy(() => import('./AdminPanel/MasterSaldoSeller.js'));
const ReportInvoice = lazy(() => import('./AdminPanel/ReportInvoice.js'));
const ReportPenarikan = lazy(() => import('./AdminPanel/ReportPenarikan.js'));
const ReportOrder = lazy(() => import('./AdminPanel/ReportOrder.js'));
const ReportPayout = lazy(() => import('./AdminPanel/ReportPayout.js'));
const ReportPerwakilan = lazy(() => import('./AdminPanel/ReportPerwakilan.js'));
const ReportSeller = lazy(() => import('./AdminPanel/ReportSeller.js'));
const ReportUser = lazy(() => import('./AdminPanel/ReportUser.js'));
// const ReportPenjualanProduk = lazy(() => import('./AdminPanel/ReportPenjualanProduk.js'));
const ReportMembership = lazy(() => import('./AdminPanel/ReportMembership.js'));
const PerwakilanSemarang = lazy(() => import('./AdminPanel/PerwakilanSemarang.js'));
const PerwakilanMedan = lazy(() => import('./AdminPanel/PerwakilanMedan.js'));
const PerwakilanBandung = lazy(() => import('./AdminPanel/PerwakilanBandung.js'));
const PerwakilanJakarta = lazy(() => import('./AdminPanel/PerwakilanJakarta.js'));
const PerwakilanSurabaya = lazy(() => import('./AdminPanel/PerwakilanSurabaya.js'));
const MasterEvent = lazy(() => import('./AdminPanel/MasterEvent.js'));
const MasterCampaign = lazy(() => import('./AdminPanel/MasterCampaign.js'));
const MasterReferral = lazy(() => import('./AdminPanel/MasterReferral.js'));
// const TempGenerateSPG = lazy(() => import('./AdminPanel/TempGenerateSPG.js'));
const ResellerKonfirmasi = lazy(() => import('./AdminPanel/ResellerKonfirmasi.js'));
const AuthRoute = lazy(() => import('./AuthRoute.js'));

//Campaign Page
const Campaign = lazy(() => import('./Campaign/Campaign.js'));
const WeekendSale = lazy(() => import('./Campaign/WeekendSale/WeekendSale.js'));
const ThemePromo = lazy(() => import('./Campaign/ThemePromo/ThemePromo.js'));
const SpecialPage = lazy(() => import('./Campaign/ThemePromo/SpecialPage.js'));

const Event = lazy(() => import('./Event/Event.js'));
// const EventEnakEco = lazy(() => import('./Event/EventEnakEco.js'));
const AbsenHarian = lazy(() => import('./Event/AbsenHarian/AbsenHarian.js'));

const DetailReportInvoice = lazy(() => import('./AdminPanel/CustomReport/DetailReportInvoice.js'));

class App extends Component {
	constructor(props) {
        super(props)      
        this.state = {
			dataCampaign: [],
			dataCustomCampaign: {
                campaign_theme:null,
                campaign_route:null,
            },
		}
	}

	componentDidMount(){
		CampaignService.getEventAll().then(res =>{
				if(res.length > 0){
					this.setState({dataCampaign: res})
				}
			}
		)

		CampaignService.getCampaignAssetCustom().then(res =>{
			this.setState({dataCustomCampaign: res})
		}
	)
	}

	render() {
		return (
			<div className="App" style={{ minHeight: "100vh" }}>
				<Suspense fallback={<LoadingApp/>}>
					<Router>
						<ScrollToTop />

						{process.env.REACT_APP_MODE === 'app' &&
							<Switch>
								<Route exact path="/" component={Homev3}/>
								<Route exact path="/reset-password" component={ResetPassword}/>

								<AuthRoute exact path="/user/" component={AccountMenu} redirect="/" role={"user"}/>
								<AuthRoute exact path="/user/profile/" component={Profile} redirect="/" role={"user"}/>
								<AuthRoute exact path="/user/address/" component={Address} redirect="/" role={"user"}/>
								<AuthRoute exact path="/notifikasi/" component={Notifikasi} redirect="/" role={"user"}/>
								<AuthRoute exact path="/user/orders/:id" component={OrderDetail} redirect="/" role={"user"}/>
								<AuthRoute exact path="/user/orders/:id/invoiceLabel" component={InvoiceLabel} redirect="/" role={"user"}/>
								<AuthRoute exact path="/user/orders/" component={Orders} redirect="/" role={"user"}/>
								<AuthRoute exact path="/user/daftarvendor/" component={DaftarVendor} redirect="/" role={"seller"}/>
								<AuthRoute exact path="/wishlist/" component={WishList} redirect="/" role={"user"}/>
								<AuthRoute exact path="/user/voucher" component={ListVoucher} redirect="/" role={"user"}/>
								<AuthRoute exact path="/user/thank-you/:id" component={ThankYouPage} redirect="/" role={"user"}/>
								<AuthRoute exact path="/membership" component={Membership} redirect="/" role={"user"}/>
								<AuthRoute exact path="/membership-statistic" component={Statistic} redirect="/" role={"user"}/>
								<AuthRoute exact path="/user/point-history" component={PointHistory} redirect="/" role={"user"}/>
								<AuthRoute exact path="/referral" component={Referral} redirect="/" role={"user"}/>
								<AuthRoute exact path="/user/absenharian" component={AbsenHarian} redirect="/" role={"user"}/>
								<Route exact path="/unsubscribe" component={UnsubscribeLetter}/>

								
								{/* Reseller */}
								{/* <AuthRoute exact path="/home-reseller" component={HomeReseller} role={"reseller"}/>
								<AuthRoute exact path="/reseller" component={Reseller} redirect="/" role={"user"}/>
								<AuthRoute exact path="/adminpaneljbok/konfirmasireseller" component={ResellerKonfirmasi} redirect="/" role={"admin"}/>
								<AuthRoute exact path="/user/vendor/product/reseller" component={ProductListReseller} redirect="/user/daftarvendor/" role={"seller"}/>
								<AuthRoute exact path="/user/vendor/product/reseller/import" component={ProductImportReseller} redirect="/user/daftarvendor/" role={"seller"}/>
								<AuthRoute exact path="/user/vendor/product/reseller/updateimport" component={ProductUpdateImportReseller} redirect="/user/daftarvendor/" role={"seller"}/>				
								<AuthRoute exact path="/user/vendor/product/reseller/:id" component={ProductDetailReseller} redirect="/user/daftarvendor/" role={"seller"}/> */}
								
								<AuthRoute exact path="/user/vendor/" component={SellerMenu} redirect="/user/daftarvendor/" role={"seller"}/>
								<AuthRoute exact path="/user/vendor/product" component={ProductList} redirect="/user/daftarvendor/" role={"seller"}/>
								<AuthRoute exact path="/user/vendor/product/import" component={ProductImport} redirect="/user/daftarvendor/" role={"seller"}/>
								<AuthRoute exact path="/user/vendor/product/updateimport" component={ProductUpdateImport} redirect="/user/daftarvendor/" role={"seller"}/>
								<AuthRoute exact path="/user/vendor/product/:id" component={ProductDetail} redirect="/user/daftarvendor/" role={"seller"}/>
								
								<AuthRoute exact path="/user/vendor/orders" component={VendorOrders} redirect="/user/daftarvendor/" role={"seller"}/>
								<AuthRoute exact path="/user/vendor/orders/:id" component={VendorOrdersDetail} redirect="/user/daftarvendor/" role={"seller"}/>
								<AuthRoute exact path="/user/vendor/orders/:id/shippinglabel" component={ShippingLabel} redirect="/user/daftarvendor/" role={"seller"}/>
								<AuthRoute exact path="/user/vendor/analisistoko" component={AnalisisToko} redirect="/user/daftarvendor/" role={"seller"}/>
								<AuthRoute exact path="/user/vendor/saldo" component={Saldo} redirect="/user/daftarvendor/" role={"seller"}/>
								<AuthRoute exact path="/user/vendor/ambilditempat" component={AmbilDitempat} redirect="/user/daftarvendor/" role={"seller"}/>
								<AuthRoute exact path="/user/vendor/productcampaign/:id" component={ProductListCampaign} redirect="/user/daftarvendor/" role={"seller"}/>
								{/* <AuthRoute exact path="/user/vendor/deposit" component={Deposit} redirect="/user/daftarvendor/" role={"seller"}/> */}

													
								<Route exact path="/tentangkami/" component={TentangKami}/>
								<Route exact path="/hubungikami/" component={HubungiKami}/>
								<Route exact path="/terms/" component={TermsCondition}/>
								<Route exact path="/kebijakanprivasi/" component={KebijakanPrivasi}/>
								<AuthRoute exact path="/allchat/" component={AllChat} redirect="/" role={"user"}/>
								<AuthRoute exact path="/allchattoko/" component={AllChatToko} redirect="/" role={"seller"}/>
								<Route exact path="/user/" component={Profile}/>
								<Route exact path="/cart/" component={Cart}/>
								<Route exact path="/checkout" component={Checkout}/>                      
								<Route exact path="/products/" component={Products}/>
								<Route exact path="/product/:id" component={Product}/>
								{/* <Route exact path="/category/:id" component={Products}/> */}
								<Route exact path="/seller/:id" component={SellerPage}/> 
								<Route exact path="/redirect/:provider" component={RedirectPage}/>
								{/* <Route exact path="/redirect/" component={Homev3}/> */}
								{/* <Route exact path="/redirect-payment" component={RedirectPayment}/> */}
								<Route exact path="/confirm-payment" component={ConfirmPayment}/>
								<Route exact path="/termsevent/:id" component={TermsEvent}/>
								{/* <Route exact path="/voucherga" component={TermsVoucher}/> */}
								{/* <Route exact path="/oktoberceria" component={Giveaway}/> */}

								{/* Event / Campaign */}
								<Route exact path="/eventpln/:id" component={Event}/>
								{/* <Route exact path="/eventenakeco" component={EventEnakEco}/> */}

								{/* Campaign */}
								{this.state.dataCampaign.map((res,idx) => {
									return (
										idx === 0 && res.priority === 1 ?
											<Route exact path={`${res.link_redirect}`} component={Campaign}/>
										: res.priority === 2 ?
											<Route exact path={`${res.link_redirect}`} component={WeekendSale}/>
										: []
									)
								})}

								{/* Custom Campaign */}
								<Route exact path={this.state.dataCustomCampaign.campaign_route} component={ThemePromo}/>
								<Route exact path={`${this.state.dataCustomCampaign.campaign_route}/special-page`} component={SpecialPage}/>

								<Route component={NotFoundPage}/>
							</Switch>
						}

						{process.env.REACT_APP_MODE === 'admin' &&
							<Switch>
								<Route exact path="/" component={LoginAdmin}/>
								<AuthRoute exact path="/adminpaneljbok" component={Dashboard} redirect="/" role={"admin"}/>
								<AuthRoute exact path="/adminpaneljbok/mastervoucher" component={MasterVoucher} redirect="/" role={"admin"}/>
								<AuthRoute exact path="/adminpaneljbok/masterdepositseller" component={MasterDepositSeller} redirect="/" role={"admin"}/>
								<AuthRoute exact path="/adminpaneljbok/mastersaldoseller" component={MasterSaldoSeller} redirect="/" role={"admin"}/>
								<AuthRoute exact path="/adminpaneljbok/reportinvoice" component={ReportInvoice} redirect="/" role={"admin"}/>
								<AuthRoute exact path="/adminpaneljbok/reportorder" component={ReportOrder} redirect="/" role={"admin"}/>
								<AuthRoute exact path="/adminpaneljbok/reportpayout" component={ReportPayout} redirect="/" role={"admin"}/>
								<AuthRoute exact path="/adminpaneljbok/reportpenarikan" component={ReportPenarikan} redirect="/" role={"admin"}/>
								<AuthRoute exact path="/adminpaneljbok/reportperwakilan" component={ReportPerwakilan} redirect="/" role={"admin"}/>
								<AuthRoute exact path="/adminpaneljbok/reportseller" component={ReportSeller} redirect="/" role={"admin"}/>
								<AuthRoute exact path="/adminpaneljbok/reportuser" component={ReportUser} redirect="/" role={"admin"}/>
								{/* <AuthRoute exact path="/adminpaneljbok/reportpenjualanproduk" component={ReportPenjualanProduk} redirect="/" role={"admin"}/> */}
								<AuthRoute exact path="/adminpaneljbok/reportmembership" component={ReportMembership} redirect="/" role={"admin"}/>
								<AuthRoute exact path="/adminpaneljbok/perwakilansemarang" component={PerwakilanSemarang} redirect="/" role={"admin"}/>
								<AuthRoute exact path="/adminpaneljbok/perwakilanmedan" component={PerwakilanMedan} redirect="/" role={"admin"}/>
								<AuthRoute exact path="/adminpaneljbok/perwakilanbandung" component={PerwakilanBandung} redirect="/" role={"admin"}/>
								<AuthRoute exact path="/adminpaneljbok/perwakilanjakarta" component={PerwakilanJakarta} redirect="/" role={"admin"}/>
								<AuthRoute exact path="/adminpaneljbok/perwakilansurabaya" component={PerwakilanSurabaya} redirect="/" role={"admin"}/>

								{/* <AuthRoute exact path="/adminpaneljbok/masterevent" component={MasterEvent} redirect="/" role={"admin"}/> */}
								<AuthRoute exact path="/adminpaneljbok/mastercampaign" component={MasterCampaign} redirect="/" role={"admin"}/>
								<AuthRoute exact path="/adminpaneljbok/masterreferral" component={MasterReferral} redirect="/" role={"admin"}/>
								{/* <AuthRoute exact path="/adminpaneljbok/generatespg" component={TempGenerateSPG} redirect="/" role={"admin"}/> */}

								<AuthRoute exact path="/adminpaneljbok/report/detailreportinvoice" component={DetailReportInvoice} redirect="/" role={"admin"}/>
							</Switch>
						}
							
						{/* <Route exact path="/homev2" component={Homev2}/> */}
					</Router>
				</Suspense>
			</div>
		);
	}
}

export default App;
