import { api } from '../../Helper'

export class CampaignService {
    
    static getEventProductByCategory = (idEvent, category) => api.get(`/users-products/query?event=${idEvent}&category=${category}&_sort=id:DESC`)
    static getEventProductAll = (idEvent, offset, start) => api.get(`/users-products/query?event=${idEvent}&_limit=${offset}&_start=${start}`)
    static getEventAll = (priority) => priority ? api.get(`/events?priority=${priority}`) : api.get(`/events`)
    static getEvent = (idEvent) => api.get(`/events/`+idEvent)
    static getEventCampaign = () => api.get(`/events/${6}`);
    
    //Custom Campaign
    static getCampaignProductCustom = () => api.get(`/users-products/campaign-product-custom`)
    static getCampaignAssetCustom = () => api.get(`/users-products/campaign-asset-custom`)
    static getCampaignProductCustomLoad = (offset, start) => api.get(`/users-products/campaign-product-custom-load?_limit=${offset}&_start=${start}`)
    static getCampaignProductCustomCount = () => api.get(`/users-products/campaign-product-custom-count`)
}