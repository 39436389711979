import React from 'react';
import ReactDOM from 'react-dom';
import App from './App.js';
import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/rsuite.css';
import './App.css';
import './index.css';
import { createMuiTheme, ThemeProvider } from "@material-ui/core";

const theme = createMuiTheme({
    palette: {
        primary: {
            main: "#ff6f6a"
        },
    }
});

//PUBLIC DEVICE
const getMobileOperatingSystem = () => {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/windows phone/i.test(userAgent)) {
      return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
      return "Android";
    }

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "iOS";
    }

    return "unknown";
}

//PUBLIC VARIABLE
window.settings = {
  webUrl: process.env.REACT_APP_WEB_URL,
  apiUrl: process.env.REACT_APP_API_URL,
  socketUrl:process.env.REACT_APP_SOCKET_URL,
  deviceOS: getMobileOperatingSystem(),
  //KEY
  apiToken: process.env.REACT_APP_API_TOKEN,
  apiSignature: process.env.REACT_APP_API_SIGNATURE,
  xenditCreditCardKey: process.env.REACT_APP_XENDIT_CC_KEY,
};

ReactDOM.render(<ThemeProvider theme={theme}><App /></ThemeProvider>, document.getElementById('root'));


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
//serviceWorker.unregister();
serviceWorker.register()
