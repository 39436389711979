import "./LoadingPage.css";
import React, { Component } from 'react';
import loading_app from './loading_app.json';
import Lottie from 'react-lottie';

class LoadingApp extends Component {

    componentDidMount(){
        document.body.style.overflow = 'hidden';
    }
    componentWillUnmount(){
        document.body.style.overflow = 'auto';
    }
    render() {
        const defaultOptions = {
            loop: true,
            autoplay: true, 
            animationData: loading_app,
            rendered: 'svg',
        };

        return (
            <div className="spanner">
                <Lottie
                    options={defaultOptions}
                    width="12rem"
                    isClickToPauseDisabled={true}
                    style={{cursor: "default", zIndex: "9999", margin: "auto",height:this.props.percentage ? "80%" : "100%"}}
                    paddingBottom="0"
                />
                {this.props.percentage?
                    <h1 style={{cursor: "default", zIndex: "9999", margin: "auto", textAlign:"center"}}>
                        {this.props.percentage+"%"}
                    </h1>
                :[]}
            </div>
        );
    }
}
export default LoadingApp;